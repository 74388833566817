:root {
  --main-font: sfpro, Arial, Helvetica, sans-serif;

  /* Colors */
  --light-color: #FFFFFF;

  --blue-color-1: #006DB3;
  --blue-color-2: #01ADFF;
  --blue-color-3: #0CDCE8;

  --yellow-color-1: #FFA833;
  --yellow-color-2: #FFF700;

  --purple-color-1: #6540E6;
  --purple-color-1-hover: #5a34e5;
  --purple-color-2: #9277F0;
  --purple-color-2-hover: #8568ee;
  --purple-color-3: #9E54FF;

  --green-color-1: #00FF5D;
  --green-color-2: #34A5AE;

  --gray-color-1: #222222;
  --gray-color-2: #232222;
  --gray-color-3: #EEEEEE;
  --gray-color-4: #262626;
  --gray-color-5: #373737;
  --gray-color-6: #707070;

  --form-error-status-color: #ff3c57;

  --footer-bkg: #373737;
  --footer-text-color: #FFFFFF;
  --footer-text-accent-color: #0CDCE8;
}

/* Defining custom theme properties */
body {
  --bkg-color: var(--light-color);
  --text-color: var(--gray-color-1);
  --toolbar-bkg: var(--bkg-color);
  --toolbar-shadow: -2px 3px 20px rgba(0, 0, 0, 0.071);

  /* hero */
  --bkg-hero: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #E8E8E829 100%) 0% 0% no-repeat padding-box;
  --hero-title-color: var(--gray-color-5);
  --hero-subtitle-color: var(--blue-color-1);
  --hero-cta-bkg: var(--purple-color-1);
  --hero-cta-bkg-hovered: var(--purple-color-1-hover);

  --hero-nav-color-1: var(--gray-color-1);
  --hero-nav-color-2: var(--gray-color-1);
  --hero-nav-color-3: var(--gray-color-1);
  --bkg-hero-img: url('assets/images/hero-design-light-shape.svg');
  --bkg-go-up-btn: var(--purple-color-1);

  --about-section-title-color: var(--blue-color-1);
  --section-title-color: var(--gray-color-5);

  /* our partners */
  --partner-filter-color: var(--gray-color-1);
  --bkg-partner-filter: var(--blue-color-1);

  /* our approach */
  --bkg-approach-img: url('assets/images/approach-light-shape.svg');
  --our-approach-line1: var(--yellow-color-1);
  --our-approach-line2: var(--blue-color-2);

  /* our services */
  --services-line1: var(--purple-color-1);
  --services-line2: var(--yellow-color-1);


  /* our products */
  --bkg-cards: var(--light-color);

  /* contact form */
  --contact-title-color: var(--gray-color-5);
  --bkg-contact-wp-button: var(--light-color);
  --contact-wp-icon-color: var(--green-color-2);
  --bkg-contact-form-button: var(--blue-color-1);
  --contact-form-button-color: var(--light-color);

}

body.dark-theme {
  --bkg-color: var(--gray-color-2);
  --text-color: var(--gray-color-3);
  --toolbar-bkg: var(--gray-color-4);
  --toolbar-shadow: -2px 3px 20px rgba(0, 0, 0, 0.188);

  /* hero */
  --bkg-hero: var(--bkg-color);
  --hero-title-color: var(--gray-color-3);
  --hero-subtitle-color: var(--blue-color-2);
  --hero-cta-bkg: var(--purple-color-2);
  --hero-cta-bkg-hovered: var(--purple-color-2-hover);

  --hero-nav-color-1: var(--yellow-color-1);
  --hero-nav-color-2: var(--blue-color-3);
  --hero-nav-color-3: var(--purple-color-2);
  --bkg-hero-img: url('assets/images/hero-design-dark-shape.svg');
  --bkg-go-up-btn: var(--blue-color-3);

  --about-section-title-color: var(--yellow-color-1);
  --section-title-color: var(--yellow-color-1);

  /* our partners */
  --partner-filter-color: var(--yellow-color-1);
  --bkg-partner-filter: var(--yellow-color-1);

  /* our approach */
  --bkg-approach-img: url('assets/images/approach-dark-shape.svg');
  --our-approach-line1: var(--blue-color-3);
  --our-approach-line2: var(--green-color-1);

  /* our services */
  --services-line1: var(--yellow-color-2);
  --services-line2: var(--green-color-1);

  /* our products */
  --bkg-cards: var(--gray-color-4);

  /* contact form */
  --contact-title-color: var(--blue-color-3);
  --bkg-contact-wp-button: var(--gray-color-4);
  --contact-wp-icon-color: var(--green-color-1);
  --bkg-contact-form-button: var(--yellow-color-1);
  --contact-form-button-color: var(--gray-color-1);
}

/* Custom fonts */
@font-face {
  font-family: sfpro;
  src: url('assets/fonts/SF-Pro-Display-Regular.ttf');
}

@font-face {
  font-family: sfpro;
  src: url('assets/fonts/SF-ProDisplay-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: sfpro;
  src: url('assets/fonts/SF-Pro-Display-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: sfpro;
  src: url('assets/fonts/SF-Pro-Display-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: sfpro;
  src: url('assets/fonts/SF-Pro-Display-Bold.otf');
  font-weight: 700;
}

/* Helpers */
.text-left {
  text-align: left !important;
}

.d-block {
  display: block !important;
}

/* General styles */
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--bkg-color, #FFFFFF);
  color: var(--text-color, #EEEEEE);
  font-family: var(--main-font);
  font-size: 17px;
  transition: background-color ease-in-out 200ms;
  position: relative;
}

#main-container {
  height: 100%;
  margin-top: 70px;
}

p {
  line-height: 1.5;
  margin: 17px 0;
}

/* Sections */
.section {
  padding: 30px 0;
  position: relative;
}

.section-title {
  margin: 0 0 20px;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  color: var(--section-title-color);
}

/* Hero */
.hero {
  min-height: calc(100% - 72px);
  padding: 0 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 50px;
  align-items: center;
  justify-items: center;
  height: auto;
  position: relative;
  background: var(--bkg-hero);
  transition: background ease-in-out 80ms;
  overflow-x: hidden;
  background-image: var(--bkg-hero-img);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.hero__shape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
}

.hero__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: 2;
}

.hero__info-cta {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

.hero__title {
  margin: 0 0 30px;
  font-weight: 300;
  font-size: 35px;
  color: var(--hero-title-color);
}

.hero__title span {
  font-weight: 500;
}

.hero__navigation {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
}

.hero__navigation a {
  text-decoration:  none;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hero__navigation a:nth-child(1) {
  color: var(--hero-nav-color-1);
}

.hero__navigation a:nth-child(1) .ng-fa-icon {
  color: var(--yellow-color-1);
}

.hero__navigation a:nth-child(2) {
  color: var(--hero-nav-color-2);
}

.hero__navigation a:nth-child(2) .ng-fa-icon {
  color: var(--blue-color-3);
}

.hero__navigation a:nth-child(3) {
  color: var(--hero-nav-color-3);
}

.hero__navigation a:nth-child(3) .ng-fa-icon {
  color: var(--purple-color-2);
}

.hero__navigation a .ng-fa-icon {
  font-size: 8px;
  margin-right: 5px;
}

.hero__cta {
  display: inline-block;
  padding: 15px 30px;
  text-decoration: none;
  color: var(--light-color);
  background-color: var(--hero-cta-bkg);
  transition: background-color ease-in-out 200ms;
  cursor: pointer;
}

.hero__cta:hover {
  background-color: var(--hero-cta-bkg-hovered);
}

.hero__image {
  /* max-width: 60%;
  height: auto; */
  height: 250px;
  width: auto;
  margin: 0 auto;
}

.hero__arrow-down {
  animation: move 1s infinite alternate;
  cursor: pointer;
}

.hero__arrow-down img {
  height: 20px;
}

@-webkit-keyframes move {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes move {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

/* Our approach section */
.our-approach {
  background-image: var(--bkg-approach-img);
  background-size: auto 100%;
  background-position: 25%;
  background-repeat: no-repeat;
}

.our-approach__text {
  text-align: left;
  position: relative;
}

.our-approach__text span {
  font-weight: 500;
}

.our-approach__text.bordered {
  padding-top: 17px;
  margin-top: 0;
}

.our-approach__text.bordered:before {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  left: 0;
  top: 0;
}

.our-approach__text.bordered-1::before {
  background-color: var(--our-approach-line1);
}

.our-approach__text.bordered-2::before {
  background-color: var(--our-approach-line2);
}

/* Metodology section */
.metodology__card {
  width: 100%;
  padding: 40px 0;
  margin-bottom: 20px;
  background-color: var(--bkg-cards);
  box-shadow: -3px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.metodology__card p {
  margin: 0;
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.metodology__card p .ng-fa-icon {
  font-size: 8px;
  margin-right: 5px;
}

.metodology__card span {
  margin-top: 10px;
}

.metodology .col-6:nth-child(1) span,
.metodology .col-6:nth-child(1) .ng-fa-icon {
  color: var(--yellow-color-1);
}

.metodology .col-6:nth-child(2) span,
.metodology .col-6:nth-child(2) .ng-fa-icon {
  color: var(--blue-color-2);
}

.metodology .col-6:nth-child(3) span,
.metodology .col-6:nth-child(3) .ng-fa-icon {
  color: var(--purple-color-3);
}

.metodology .col-6:nth-child(4) span,
.metodology .col-6:nth-child(4) .ng-fa-icon {
  color: var(--blue-color-1);
}

.metodology .col-6:nth-child(5) span,
.metodology .col-6:nth-child(5) .ng-fa-icon {
  color: var(--blue-color-3);
}

/* Tools section */
.tools img {
  display: block;
  height: 80px;
  width: auto;
  margin: 0 auto 30px;
}

/* Skills section */
.skills-card {
  display: inline-block;
  padding: 10px 30px;
  background-color: var(--bkg-cards);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
}

.skills-card p {
  margin: 0;
  display: inline-block;
  text-align: center;
}

/* Services cards section */
.service-card {
  display: block;
  width: 100%;
  height: 200px;
  max-width: 160px;
  margin: 0 auto 20px;
  border-radius: 20px;
  cursor: pointer;
  padding: 15px;
  text-decoration: none;
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-shadow: -3px 4px 30px rgba(0, 0, 0, 0.2);
}

.service-card img {
  width: 45px;
  height: auto;
  margin-bottom: 20px;
}

.service-cards .row div:nth-of-type(1) > .service-card {
  background-color: var(--purple-color-1);
}

.service-cards .row div:nth-of-type(2) .service-card {
  background-color: var(--blue-color-2);
}

.service-cards .row div:nth-of-type(3) .service-card {
  background-color: var(--blue-color-3)
}

.service-cards .row div:nth-of-type(4) .service-card {
  background-color: var(--yellow-color-1)
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  /* Hero */
  .hero__wrapper {
    padding: 20px 0;
  }

  .hero__title {
    font-size: 50px;
  }

  .hero__navigation {
    margin-bottom: 40px;
  }

  .hero__image {
    max-width: 50%;
    height: auto;
    margin: 30px auto;
  }

  /* Tools section */
  .tools img {
    height: 85px;
  }

  /* Services cards section */
  .service-card {
    height: 230px;
    max-width: 200px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  #main-container {
    margin-top: 80px;
  }

  /* Sections */
  .section {
    padding: 50px 0;
  }

  .section-title {
    font-size: 28px;
  }

  /* Hero */
  .hero__wrapper {
    max-width: 720px;
    margin: 0 auto;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
  }

  .hero__info-cta {
    width: 50%;
    text-align: left;
  }

  .hero__navigation {
    justify-content: space-between;
  }

  .hero__image {
    align-self: center;
    justify-self: unset;
    /* max-width: 40%; */
    height: 280px;
    margin: 0;
  }

  .hero__arrow-down {
    grid-column: 1 / 3;
  }

  /* our approach section */
  .our-approach {
    background-size: auto 100%;
    background-position: 200%;
  }

  /* Tools section */
  .tools img {
    margin-bottom: 30px;
  }

  /* Services cards section */
  .service-card {
    height: 200px;
  }

  .service-card span {
    font-size: 20px;
  }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Hero */
  .hero__wrapper {
    max-width: 960px;
    justify-content: space-evenly;
  }

  .hero__title {
    font-size: 58px;
  }

  .hero__navigation {
    max-width: 350px;
  }

  .hero__cta {
    font-size: 22px;
  }

  .hero__image {
    /* max-width: 30%; */
    height: 380px;
    width: auto;
  }

  .hero__arrow-down {
    align-self: start;
  }

  /* Our approach section */
  .our-approach {
    background-size: auto 130%;
    background-position: 112%;
  }

  .our-approach .col-12:last-child p {
    padding-left: 30px;
  }

  .our-approach .col-12:last-child p::before {
    left: 30px;
  }

  /* Service cards section */
  .service-card {
    height: 230px;
  }

  .service-card span {
    font-size: 20px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Hero */
  .hero__wrapper {
    max-width: 1140px;
    justify-content: space-evenly;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
